/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Divider, Card, Text, Box, Flex } from '@theme-ui/components'
import Image from 'gatsby-image'
import moment from 'moment'
import { Link } from 'gatsby'

const Tag = ({ children }) => {
  return (
    <span
      sx={{
        fontSize: 0,
        ':not(:last-child)': { mr: 2 },
        backgroundColor: '#feeb65',
        px: '5px',
        py: '2px',
        mb: '8px',
        borderRadius: '5px',
        color: 'rgba(0, 0, 0, 0.7)',
      }}
    >
      {children}
    </span>
  )
}
const TagList = ({ tags }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: '16px' }}>
      {tags.map((tag, index) => {
        return <Tag key={`${tag}${index}`}>{tag}</Tag>
      })}
    </Box>
  )
}

const RecentPost = ({ title, subtitle, date, tags, image, sxx, slug, placeholder }) => {
  const formattedDate = moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY')

  return (
    <Card variant="recentPost" sx={sxx}>
      <Link to={slug}>
        <Image fluid={(image || placeholder).childImageSharp.fluid} />
      </Link>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            '*': {
              textDecoration: 'none',
              fontSize: 3,
              fontWeight: 'bold',
              color: 'text',
            },
            mb: 2,
          }}
        >
          <Link to={slug}>{title}</Link>
        </Box>
        <Text sx={{ fontSize: 0, color: 'grey', mb: 2 }}>{formattedDate}</Text>
        <TagList tags={tags} />
        <Divider sx={{ color: 'lightgrey' }} />
        <Text sx={{ fontSize: 2, mb: 2 }}>{subtitle}</Text>
        <Flex
          sx={{ width: '100%', justifyContent: 'flex-end', mb: 1, '*': { textDecoration: 'none' } }}
        >
          <Link to={slug}>Read more ></Link>
        </Flex>
      </Box>
    </Card>
  )
}

export default RecentPost
