/** @jsx jsx */
import { jsx } from 'theme-ui'
import NavBar from './navbar'
import Footer from './footer'
import { Fragment } from 'react'
import SEO from './seo'

export default function PageLayout({ title, description, children }) {
  return (
    <Fragment>
      <SEO title={title} description={description} keywords={['spanish']} />
      <div>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            backgroundColor: 'primary',
            px: [2, 3, 3],
          }}
        >
          <div
            sx={{
              width: ['100%', '100%', '960px'],
            }}
          >
            <NavBar />
          </div>
        </div>

        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            backgroundColor: 'transparent',
            px: [2, 3, 3],
            mt: 3,
            mb: 3,
          }}
        >
          <div
            sx={{
              width: ['100%', '100%', '960px'],
            }}
          >
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
