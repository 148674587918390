/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Flex, Box } from '@theme-ui/components'
import '../components/layout.css'
import { graphql } from 'gatsby'
import RecentPost from '../components/recent-post'
import PageLayout from '../components/page-layout'

const RecentPosts = ({ posts, placeholder }) => {
  return (
    <Flex sx={{ flexDirection: 'row', m: -2, flexWrap: 'wrap' }}>
      {posts.map((post, index) => {
        const oneThird = '33.33333%'
        return (
          <Box key={`${post.title}${index}`} sx={{ width: ['100%', oneThird, oneThird], p: 2 }}>
            <RecentPost sxx={{}} {...post} placeholder={placeholder}></RecentPost>
          </Box>
        )
      })}
    </Flex>
  )
}

export default function BlogPage({ data }) {
  const nodes = data.allMdx.edges.map(edge => edge.node)
  const posts = nodes.map(node => {
    return { ...node.frontmatter, slug: node.fields.slug }
  })

  return (
    <PageLayout title="Blog">
      <h1>Blog</h1>
      <RecentPosts posts={posts} placeholder={data.placeholder} />
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { fields: { slug: { ne: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            date
            tags
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "post-image-placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
